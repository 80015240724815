import React from "react"

import Layout, {withCenter} from "../components/layout"
import SEO from "../components/seo"
import { Box } from "../components/responsiveComponents"
import {graphql} from "gatsby"
import {fluidImage, allProjectImages} from "../components/fragments"
import { outerSpacing, twoColGrid, threeColGrid } from "../theme/spacing"
import { ProjectList } from "../components/projects"
import { dark } from "../theme/colors"
import { Hero } from "../components/hero"
import ContactUs from "../components/contact"
import arches from "../images/arches.png"

const InnerContainer = withCenter(Box)
const CenteredContact = withCenter(ContactUs)

const ProjectsPage = ({data}) => (
    <Layout>
      <SEO 
        title="Portfolio Desain Arsitektur dan Interior"   
        description="Konsultan dan kontraktor arsitektur & interior. Melayani jasa desain dan renovasi rumah, apartemen, kantor, cafe, restaurant di kawasan Jakarta dan sekitarnya"      
        keywords={[`desain`, `porftolio`, `interior`, `arsitek`]} />    
      <Hero 
            imageFluid={data.hero.childImageSharp.fluid}
            heroTitle="Our Projects"
            heroSubtitle="Here are our projects, ranging from Private Residences, Apartments, Offices to Commercial Projects."
            darkness={0.45} 
            />
      <InnerContainer {...outerSpacing} {...dark}>
        <ProjectList
            projects={data.allProjects.nodes} 
            images={data.allProjectImages.nodes} 
            titleColor="neutral.8" 
            subtitleColor="neutral.7"
            childBoxProps={{
                ...twoColGrid,
                py: "vert.1",
                my: "vert.3",
            }}
            boxProps={{
                mt: "vert.5"
            }}
        />        
      </InnerContainer>      
      <CenteredContact {...outerSpacing} bg="neutral.8" backgroundImage={`url(${arches})`}/>
    </Layout>
  )
  
export const query = graphql`    
  query {
    
    allProjects:
        allProjectsYaml(sort: {fields: [year, name], order: [DESC, ASC]}) {
            nodes {
                name
                location
                slug
                imageFolder
            }
        }

    ...allProjectImages

    hero: file(relativePath: { eq: "hero-project.jpg" }) {
        childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  }
`

export default ProjectsPage

