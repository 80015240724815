import * as React from "react"
import styled from "styled-components"
import {color} from "styled-system"
import { graphql, StaticQuery } from 'gatsby'
import {Box, Flex, Text, Button} from "./responsiveComponents"
import BackgroundImage from 'gatsby-background-image'
import { FluidObject } from "gatsby-image";
import { OutlineButton } from "./link";

const HeroText = styled.h2`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  ${color}
`

const SubText = styled(Text)`
  text-align: center;  
  font-weight: 200;
`

export interface HeroProps {
  imageFluid: FluidObject,
  heroTitle: string,
  heroSubtitle: string,
  darkness: number,
  heroButtonTitle?: string,
  className?: string
}

const BackgroundHero = (props: HeroProps) => {
  const HeroButton = props.heroButtonTitle ? 
    ( 
    <Box>
      <OutlineButton px={7} href="/#contact" className="hvr-sweep-to-bottom">{props.heroButtonTitle}</OutlineButton>
    </Box>
    ) : null
    
  return (
          <BackgroundImage fluid={props.imageFluid} className={props.className}>
            <Flex 
              alignItems="center" 
              flexDirection="column" 
              justifyContent="center" 
              py={["vert.sm.16", "240px"]}>

              <Box my={"vert.5"} px={4}>
                  <HeroText color="neutral.8">{props.heroTitle}</HeroText>                                
                  <SubText color="neutral.8">{props.heroSubtitle}</SubText>                                
              </Box>                          
              
              {HeroButton}
            </Flex>
          </BackgroundImage>
       )         
}

export const Hero = styled(BackgroundHero)`
  width: 100%;
  background-position: top left;
  background-size: cover;  
  opacity: 1 !important;
  box-shadow: inset 0 0 0 1300px rgba(0,0,0, ${props => props.darkness});
`
